import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import albumStyles from './album.module.scss'

const Album = (props) => {
    const { title, description, images } = props.data.contentfulAlbum;
    const [currentImage, setCurrentImage] = useState(0);
    const [nextImage, setNextImage] = useState(-1);
    const [prevImage, setPrevImage] = useState(-1);

    const useScroll = () => {
        const ref = useRef(null)
        const executeScroll = () => {
            window.scrollTo(0, ref.current.offsetTop - 10)
        }
        const htmlElementAttributes= {ref}
    
        return [executeScroll, htmlElementAttributes]
    }

    const [executeScroll, scrollHtmlAttributes] = useScroll()

    useEffect(() => {

        if(images.length > 1) {
            setNextImage(1);
        }
    }, [])

    const clickNext = () => {
        if(currentImage + 1 >= images.length) return;
        setCurrentImage(currentImage + 1)
        setPrevImage(currentImage);
        if(currentImage + 2 < images.length) {
            setNextImage(currentImage + 2)
        } else {
            setNextImage(-1)
        }
        executeScroll()
    }

    const clickPrev = () => {
        if(currentImage - 1 < 0) return;
        setCurrentImage(currentImage - 1)
        setNextImage(currentImage)
        
        if(currentImage -2 >= 0) {
            setPrevImage(currentImage - 2);    
        } else {
            setPrevImage(-1);
        }
        executeScroll()
    }

    const NonStretchedImage = props => {
        if(typeof document === "undefined") return <Img {...props} />;

        const maxHeight = (80 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) / 100;
        const maxWidth = (80 * Math.max(document.documentElement.clientWidth, window.innerWidth || 0)) / 100;

        let height = maxWidth / props.fluid.aspectRatio;
        let width = maxHeight * props.fluid.aspectRatio;

        if(height > maxHeight) {
            height = maxHeight + 'px';
        } else if(width > maxWidth) {
            width = maxWidth + 'px';
        }

        let normalizedProps = props
        if (props.fluid) {
          normalizedProps = {
            ...props,
            style: {
              ...(props.style || {}),
              height,
              width,
              margin: "0 auto", // Used to center the image
            },
          }
        }
      
        return <Img {...normalizedProps} />
    }

    return (
        <Layout>
            <h1>{title}</h1>
            <div>{description && description.json && documentToReactComponents(description.json)}</div>
            <ul className="image-list" {...scrollHtmlAttributes}>
                <li className={[albumStyles.previous, 'prev'].join(' ')} onClick={clickPrev}>
                    { prevImage >= 0 && <Img fluid={images[prevImage].fluid} alt={images[prevImage].title} /> }
                </li>
                <li className="active">
                    <NonStretchedImage fluid={images[currentImage].fluid} alt={images[currentImage].title} />
                    <div>{images[currentImage].description}</div>
                </li>
                <li className={[albumStyles.previous, 'next'].join(' ')} onClick={clickNext}>
                    { nextImage >= 0 && <Img fluid={images[nextImage].fluid} alt={images[nextImage].title} /> }
                </li>
            </ul>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        contentfulAlbum(slug: { eq: $slug } ) {
            title
            description {
                json
            }
            images {
                title
                description
                fluid(maxWidth: 1024) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }
`

export default Album;
