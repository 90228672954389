import React from 'react';
import { Link } from 'gatsby'
import logo from '../images/header-image.png'
import headerStyles from './header.module.scss'

const Header = () => {
    return (
        <header>
            <Link to='/'><img className={headerStyles.logo} src={logo} alt="Virginia Morrison Photography" /></Link>
            <nav>
                <ul>
                    <li><Link to="/albums">Albums</Link></li>
                    <li><Link to="/bio">Bio</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;