import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { OutboundLink } from 'gatsby-plugin-google-analytics'



const Footer = () => {
    return (
        <footer>
            <nav>
                <ul>
                    <li><Link to="/albums">Albums</Link></li>
                    <li><Link to="/bio">Bio</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><OutboundLink href="https://www.instagram.com/mulefan/"><FontAwesomeIcon icon={faInstagram} /></OutboundLink></li>
                    <li><OutboundLink href="https://www.linkedin.com/in/virginia-morrison-9752b922/"><FontAwesomeIcon icon={faLinkedin} /></OutboundLink></li>
                </ul>
            </nav>
            <h4>&copy; Copyright 2019 Virginia Morrison</h4>
        </footer>
    )
}

export default Footer;